import "./projectDetails.css";
import Table from "react-bootstrap/Table";
import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaCopy, FaCheck } from "react-icons/fa";
import useEmblaCarousel from "embla-carousel-react";
import AutoHeight from "embla-carousel-auto-height";
import Modal from "react-modal";

export function ProjectDetails() {
  const { link } = useParams(); // Получаем link из URL
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [AutoHeight()]);
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomImage, setZoomImage] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0); // Текущий слайд
  const [isCopied, setIsCopied] = useState(false); // Состояние для управления состоянием копирования
  const tweenFactor = useRef(0);
  const tweenNodes = useRef([]);
  const [progressBarWidth, setProgressBarWidth] = useState(0);
  const autoScrollInterval = useRef(null); // Ссылка на интервал автопрокрутки

  useEffect(() => {
    // Проверка статуса базовой страницы "project-details"
    fetch(`/api/check-page-status/project-details/`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== 'online') {
          navigate('/error');  // Редирект на страницу 404, если базовая страница не в статусе "онлайн"
        }
      })
      .catch((error) => {
        console.error("Ошибка при проверке статуса страницы:", error);
        navigate('/error');  // В случае ошибки тоже редирект на страницу 404
      });

    // Запрашиваем данные о проекте по link, если базовая страница "online"
    fetch(`/api/project-details/${link}/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Project not found");
        }
        return response.json();
      })
      .then((data) => setProject(data))
      .catch((error) => {
        console.error("Ошибка при загрузке данных о проекте:", error);
        navigate('/error');  // Если проект не найден, редирект на страницу 404
      });
  }, [link, navigate]);

  useEffect(() => {
    if (!emblaApi) return;

    const setTweenNodes = (emblaApi) => {
      tweenNodes.current = emblaApi.slideNodes().map((slideNode) => {
        return slideNode.querySelector(".embla__parallax__layer");
      });
    };

    const setTweenFactor = (emblaApi) => {
      tweenFactor.current = 0.2 * emblaApi.scrollSnapList().length;
    };

    const tweenParallax = (emblaApi) => {
      const engine = emblaApi.internalEngine();
      const scrollProgress = emblaApi.scrollProgress();
      const slidesInView = emblaApi.slidesInView();

      emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
        let diffToTarget = scrollSnap - scrollProgress;
        const slidesInSnap = engine.slideRegistry[snapIndex];

        slidesInSnap.forEach((slideIndex) => {
          if (!slidesInView.includes(slideIndex)) return;

          const translate = diffToTarget * (-1 * tweenFactor.current) * 100;
          const tweenNode = tweenNodes.current[slideIndex];
          tweenNode.style.transform = `translateX(${translate}%)`;
        });
      });
    };

    const updateProgressBar = () => {
      if (!emblaApi) return;
      const slideWidth = emblaApi.slideNodes()[emblaApi.selectedScrollSnap()].getBoundingClientRect().width;
      setProgressBarWidth(slideWidth);
    };

    setTweenNodes(emblaApi);
    setTweenFactor(emblaApi);
    tweenParallax(emblaApi);

    emblaApi.on("reInit", () => {
      setTweenNodes(emblaApi);
      setTweenFactor(emblaApi);
      tweenParallax(emblaApi);
      updateProgressBar();
    }).on("scroll", () => {
      tweenParallax(emblaApi);
      updateProgressBar();
      setCurrentSlide(emblaApi.selectedScrollSnap()); // Обновляем текущий слайд
    });

    updateProgressBar();

    // Автоматическое воспроизведение
    autoScrollInterval.current = setInterval(() => {
      if (emblaApi) emblaApi.scrollNext();
    }, 3000); // Прокрутка каждые 3 секунды

    return () => clearInterval(autoScrollInterval.current); // Очистка интервала при размонтировании
  }, [emblaApi]);

  const handleImageClick = (image, index) => {
    setZoomImage(image);
    setCurrentSlide(index); // Устанавливаем текущий слайд
    setIsZoomed(true);
    document.body.classList.add('modal-open'); // Добавляем класс, чтобы скрыть хедер
    clearInterval(autoScrollInterval.current); // Останавливаем автоматическую прокрутку
  };

  const closeZoom = () => {
    setIsZoomed(false);
    setZoomImage(null);
    document.body.classList.remove('modal-open'); // Убираем класс при закрытии модального окна
    if (emblaApi) {
      emblaApi.scrollTo(currentSlide); // Перемещаем карусель на текущий слайд
    }
    autoScrollInterval.current = setInterval(() => {
      if (emblaApi) emblaApi.scrollNext();
    }, 3000); // Перезапускаем автоматическую прокрутку
  };

  const handlePrevClick = () => {
    const newIndex = currentSlide > 0 ? currentSlide - 1 : project.images.length - 1;
    setCurrentSlide(newIndex);
    setZoomImage(project.images[newIndex].image);
  };

  const handleNextClick = () => {
    const newIndex = currentSlide < project.images.length - 1 ? currentSlide + 1 : 0;
    setCurrentSlide(newIndex);
    setZoomImage(project.images[newIndex].image);
  };

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Возвращаем иконку через 2 секунды
    });
  };

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <div className="projectDetails">
      <div className="pDetails-header">
        {project.main_image && (
          <img src={project.main_image} alt={project.title} className="main-image" />
        )}
      </div>
      <div className="details">
        <div className="d-client-info">
          <Table>
            <tbody>
              {project.client && (
                <tr>
                  <td>Client</td>
                  <td>{project.client}</td>
                </tr>
              )}
              {project.category && (
                <tr>
                  <td>Category</td>
                  <td>{project.category.name}</td>
                </tr>
              )}
              {project.tags && project.tags.length > 0 && (
                <tr>
                  <td>Tags</td>
                  <td>{project.tags.map((tag) => tag.name).join(", ")}</td>
                </tr>
              )}
              {project.link && (
                <tr>
                  <td>Link</td>
                  <td>
                    <a href={project.link} target="_blank" rel="noopener noreferrer">
                      {project.link}
                    </a>
                    {isCopied ? (
                      <FaCheck style={{ marginLeft: '10px', color: 'green' }} />
                    ) : (
                      <FaCopy onClick={copyLinkToClipboard} style={{ marginLeft: '10px', cursor: 'pointer' }} />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        <div className="d-project-info">
          <h2>{project.title}</h2>
          <p>{project.description}</p>
        </div>
      </div>

      <div className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {project.images
              .sort((a, b) => a.order - b.order)
              .map((image, index) => (
                <div
                  className="embla__slide"
                  key={index}
                  onClick={() => handleImageClick(image.image, index)}
                >
                  <div className="embla__parallax">
                    <div className="embla__parallax__layer">
                      <img
                        className="embla__slide__img embla__parallax__img"
                        src={image.image}
                        alt={image.title}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="carousel-progress" style={{ width: `${progressBarWidth}px` }}>
        <div className="carousel-progress-bar"></div>
      </div>

      <Modal
        isOpen={isZoomed}
        onRequestClose={closeZoom}
        contentLabel="Zoomed Image"
        className="zoomModal"
        overlayClassName="zoomOverlay"
      >
        <img src={zoomImage} alt="Zoomed" className="zoomedImage" />
        <button onClick={handlePrevClick} className="zoomNavButton prev">←</button>
        <button onClick={handleNextClick} className="zoomNavButton next">→</button>
        <button onClick={closeZoom} className="closeZoomButton">Close</button>
      </Modal>
    </div>
  );
}
