import "./blog.css";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export function Blog() {
  const [newsList, setNewsList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Проверка статуса страницы "Blog"
    fetch(`/api/check-page-status/blog/`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== 'online') {
          navigate('/error');  // Редирект на страницу 404, если страница не в статусе "онлайн"
        }
      })
      .catch((error) => console.error('Ошибка при проверке статуса страницы:', error));

    // Fetch all news from the API
    fetch('/api/news/')
      .then((response) => response.json())
      .then((data) => {
        setNewsList(data);
      })
      .catch((error) => console.error('Ошибка при загрузке новостей:', error));
  }, [navigate]);

  return (
    <div className="blog">
      <div className="blog-header">
        <h1>
          Articles & News<p>Home / Blog</p>
        </h1>
      </div>

      {newsList.length > 0 && (
        <div className="latestNews">
          <h1>Latest Post</h1>
          <div className="lNews">
            <div className="ln-img">
              <img src={newsList[0].main_image} alt="news" />
            </div>
            <div className="ln-text">
              <div className="ln-subtext">
                <h1>{newsList[0].title}</h1>
                <p>{newsList[0].interesting_story}</p>
              </div>
                <div className="ln-detail">
                    <p className="ac-date">{new Date(newsList[0].published_date).toLocaleDateString()}</p>
                    <Link to={`/blog-details/${newsList[0].slug}`}>
                        <button>
                            <IoIosArrowForward/>
                        </button>
                    </Link>
                </div>
            </div>
          </div>
        </div>
      )}

        <div className="blogArticles">
        <h1>Articles & News</h1>
        <div className="blog-art-list">
          {newsList.slice(1).map((newsItem, index) => (
            <div className="blog-art notaken" key={index}>
              <div className="blog-art-header">
                <img src={newsItem.main_image} alt="article" />
              </div>
              <div className="blog-art-content">
                <p>{newsItem.title}</p>
                <div className="ba-detail">
                  <p className="ba-date">{new Date(newsItem.published_date).toLocaleDateString()}</p>
                  <Link to={`/blog-details/${newsItem.slug}`}>
                    <button>
                      <IoIosArrowForward />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
