import React, { useEffect, useState } from "react";
import "./home.css";
import "./homeResponsive.css";
import { Link, useNavigate } from "react-router-dom"; // Добавлено useNavigate
import { BsArrowRight, BsTelephone } from "react-icons/bs";
import { AiOutlineArrowRight } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import CountUp from "react-countup";

export function Home() {
  const [mainContent, setMainContent] = useState({});
  const [testimonials, setTestimonials] = useState([]);
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);
  const [services, setServices] = useState([]);
  const [contactInfo, setContactInfo] = useState("");
  const [projects, setProjects] = useState([]);
  const [news, setNews] = useState([]);
  const [partners, setPartners] = useState([]);
  const [mainPageImages, setMainPageImages] = useState([]);
  const [statistics, setStatistics] = useState(null);

  const navigate = useNavigate(); // Для использования перехода через код

  useEffect(() => {
    // Использование Promise.all для объединения запросов
    Promise.all([
      fetch("/api/main-page/").then((res) => res.json()),
      fetch("/api/testimonials/").then((res) => res.json()),
      fetch("/api/services/").then((res) => res.json()),
      fetch("/api/contact-info/").then((res) => res.json()),
      fetch("/api/projects/").then((res) => res.json()),
      fetch("/api/news/").then((res) => res.json()),
      fetch("/api/partners/").then((res) => res.json()),
      fetch("/api/main-page-images/").then((res) => res.json()),
      fetch("/api/statistics/").then((res) => res.json())
    ])
      .then(([
        mainPageData,
        testimonialsData,
        servicesData,
        contactInfoData,
        projectsData,
        newsData,
        partnersData,
        mainPageImagesData,
        statisticsData
      ]) => {
        // Установка данных после успешного завершения всех запросов
        setMainContent(mainPageData);
        setTestimonials(testimonialsData);
        setServices(servicesData.slice(0, 3));
        setContactInfo(contactInfoData.contact_phone);
        setProjects(projectsData.slice(0, 4));
        setNews(newsData.sort((a, b) => new Date(b.published_date) - new Date(a.published_date)).slice(0, 3));
        setPartners(partnersData);
        setMainPageImages(mainPageImagesData);
        setStatistics(statisticsData || null);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const imageOrder1 = mainPageImages.find((image) => image.order === 1);
  const imageOrder2 = mainPageImages.find((image) => image.order === 2);

  const handleNextTestimonial = () => {
    setCurrentTestimonialIndex((prevIndex) =>
      prevIndex === testimonials.length - 3 ? 0 : prevIndex + 1
    );
  };

  const handlePrevTestimonial = () => {
    setCurrentTestimonialIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 3 : prevIndex - 1
    );
  };

  // Функция для перехода на страницу проекта
  const handleProjectClick = (projectLink) => {
    navigate(`/project-details/${projectLink}`);
  };

  const renderNews = () => {
    if (news.length === 1) {
      return (
        <div className="news-wrapper single-news">
          <div className="article" key={news[0].id}>
            <div className="article-header">
              <img src={news[0].main_image} alt="article"></img>
            </div>
            <div className="article-content">
              <p>{news[0].title}</p>
              <div className="ac-detail">
                <p className="ac-date">
                  {new Date(news[0].published_date).toLocaleDateString()}
                </p>
                <Link to={`/blog-details/${news[0].slug}`}>
                  <button>
                    <IoIosArrowForward />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (news.length === 2) {
      return (
        <div className="news-wrapper two-news">
          <div className="article" key={news[0].id}>
            <div className="article-header">
              <img src={news[0].main_image} alt="article"></img>
            </div>
            <div className="article-content">
              <p>{news[0].title}</p>
              <div className="ac-detail">
                <p className="ac-date">
                  {new Date(news[0].published_date).toLocaleDateString()}
                </p>
                <Link to={`/blog-details/${news[0].slug}`}>
                  <button>
                    <IoIosArrowForward />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="article" key={news[1].id}>
            <div className="article-header">
              <img src={news[1].main_image} alt="article"></img>
            </div>
            <div className="article-content">
              <p>{news[1].title}</p>
              <div className="ac-detail">
                <p className="ac-date">
                  {new Date(news[1].published_date).toLocaleDateString()}
                </p>
                <Link to={`/blog-details/${news[1].slug}`}>
                  <button>
                    <IoIosArrowForward />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (news.length === 3) {
      return (
        <div className="news-wrapper three-news">
          <div className="article" key={news[2].id}>
            <div className="article-header">
              <img src={news[2].main_image} alt="article"></img>
            </div>
            <div className="article-content">
              <p>{news[2].title}</p>
              <div className="ac-detail">
                <p className="ac-date">
                  {new Date(news[2].published_date).toLocaleDateString()}
                </p>
                <Link to={`/blog-details/${news[2].slug}`}>
                  <button>
                    <IoIosArrowForward />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="article" key={news[0].id}>
            <div className="article-header">
              <img src={news[0].main_image} alt="article"></img>
            </div>
            <div className="article-content">
              <p>{news[0].title}</p>
              <div className="ac-detail">
                <p className="ac-date">
                  {new Date(news[0].published_date).toLocaleDateString()}
                </p>
                <Link to={`/blog-details/${news[0].slug}`}>
                  <button>
                    <IoIosArrowForward />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="article" key={news[1].id}>
            <div className="article-header">
              <img src={news[1].main_image} alt="article"></img>
            </div>
            <div className="article-content">
              <p>{news[1].title}</p>
              <div className="ac-detail">
                <p className="ac-date">
                  {new Date(news[1].published_date).toLocaleDateString()}
                </p>
                <Link to={`/blog-details/${news[1].slug}`}>
                  <button>
                    <IoIosArrowForward />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="home">
      <div
        className="homeNews"
        style={{ backgroundImage: `url(${imageOrder1 ? imageOrder1.image : ""})` }}
      >
        {mainContent.main_image_title && (
          <>
            <h1>{mainContent.main_image_title}</h1>
            <p>{mainContent.main_image_description}</p>
            <Link to="/aboutus">
              <button>
                Aflați despre noi
                <BsArrowRight style={{ marginLeft: "2%", color: "#CDA274" }} />
              </button>
            </Link>
          </>
        )}
      </div>

      <div className="homeOther">
        <div className="homePlans">
          {services.map((service, ind) => (
            <div className="homePlan" key={ind}>
              <h2>{service.title}</h2>
              <p dangerouslySetInnerHTML={{ __html: service.description }}></p>
              <Link to={`/service-single/${service.slug}`}>
                <button>
                  Află mai multe
                  <BsArrowRight
                    style={{ marginLeft: "4%", color: "#CDA274" }}
                  />
                </button>
              </Link>
            </div>
          ))}
        </div>
        <div className="homeAboutUs">
          <div className="hp-subtext">
            {mainContent.phone_section_title && (
              <>
                <h1>{mainContent.phone_section_title}</h1>
                <p>{mainContent.phone_section_description}</p>
                <div className="callUs">
                  <div className="phoneNum">
                    <p className="h-icon">
                      <BsTelephone />
                    </p>
                    <a href={`tel:${contactInfo}`}>
                      <p>
                        {contactInfo}
                        <br />
                        <span>Sună-ne oricând</span>
                      </p>
                    </a>
                    <br />
                  </div>
                  <Link to={`/contact`}>
                    <button>
                      Obțineți o consultație
                      <BsArrowRight
                        style={{ marginLeft: "2%", color: "#CDA274" }}
                      />
                    </button>
                  </Link>
                </div>
              </>
            )}
          </div>
          <div className="hp-img">
            <img src={imageOrder2 ? imageOrder2.image : ""} alt="concept"></img>
          </div>
        </div>

        {testimonials.length > 0 && (
          <div className="people-thoughts">
            <h1>Ce cred oamenii despre noi</h1>
            <div className="testimonial-container">
              <MdChevronLeft className="testimonial-arrow left" onClick={handlePrevTestimonial} />
              <div className="people">
                {testimonials.slice(currentTestimonialIndex, currentTestimonialIndex + 3).map((testimonial, ind) => (
                  <div className="person" key={ind}>
                    <div className="person-title">
                      <div>
                        <img src={testimonial.user_image} alt="customer"></img>
                      </div>
                      <p>
                        {testimonial.user_name}
                        <br />
                        <span>{testimonial.location}</span>
                      </p>
                    </div>
                    <p className="thought">{testimonial.content}</p>
                  </div>
                ))}
              </div>
              <MdChevronRight className="testimonial-arrow right" onClick={handleNextTestimonial} />
            </div>
          </div>
        )}

        <div className="home-brands">
          <ul>
            {partners.map((partner, ind) => (
              <ol key={ind}>
                <img src={partner.logo} alt={partner.name}></img>
              </ol>
            ))}
          </ul>
        </div>
        <div className="homeProjects">
          <h1>Urmărește proiectele noastre</h1>
          <p>
            Detaliile și designul creează o poveste
          </p>
          <div className="hp-list">
            {projects.map((project, ind) => (
              <div
                className="hp-project"
                key={ind}
                onClick={() => handleProjectClick(project.link)}
              >
                <div className="hp-pro-img">
                  <img src={project.main_image} alt="project"></img>
                </div>
                <div className="hp-pro-detail">
                  <div className="hp-pro-info">
                    <p className="hp-prj-title">{project.title}</p>
                    <p className="hp-prj-path">
                      {project.category.name} /{" "}
                      {project.tags.map((tag) => tag.name).join(", ")}
                    </p>
                  </div>
                  <div className="hp-pro-btn">
                    <Link to={`/project-details/${project.link}`} onClick={(e) => e.stopPropagation()}>
                      <button>
                        <IoIosArrowForward />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {news.length > 0 && (
        <div className="articleNews">
          <h1>Articole & Ştiri</h1>
          <p>
            Noutăți și sfaturi - creați cu noi spații stilate
          </p>
          <div className="articles">
            {renderNews()}
          </div>
        </div>
      )}

      <div className="h-interno">
        <h1>Gata pentru o schimbare?</h1>
        <p>Contactați-ne pentru a discuta cum vă putem ajuta să creați spațiul perfect care să se potrivească stilului dumneavoastră</p>
        <Link to={`/contact`}>
          <button>
            Contactați cu noi
            <AiOutlineArrowRight
              style={{ marginLeft: "5px" }}
              color="#292F36"
            />
          </button>
        </Link>
      </div>
    </div>
  );
}
