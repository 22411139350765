import "./project.css";
import { useEffect, useState } from "react";
import { ProjectCard } from "./projectCard";
import { useNavigate } from "react-router-dom";

export function Project() {
  const [projects, setProjects] = useState([]);
  const [imageTags, setImageTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Используем Promise.all для объединения запросов
    fetch('/api/check-page-status/projects/')
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== 'online') {
          navigate('/error');  // Редирект на страницу ошибки, если страница не в статусе "онлайн"
        } else {
          // Если страница в статусе "онлайн", выполняем запросы на проекты и теги изображений
          Promise.all([
            fetch('/api/projects/').then((res) => res.json()),
            fetch('/api/image-tags/').then((res) => res.json())
          ])
            .then(([projectsData, imageTagsData]) => {
              setProjects(projectsData);
              setImageTags(imageTagsData);
            })
            .catch((error) => {
              console.error("Ошибка при загрузке данных:", error);
            });
        }
      })
      .catch((error) => console.error('Ошибка при проверке статуса страницы:', error));
  }, [navigate]);

  const handleTagSelection = (tag) => {
    if (selectedTag === tag) {
      setSelectedTag(null);
    } else {
      setSelectedTag(tag);
    }
  };

  const filteredImages = selectedTag
    ? projects.flatMap(project =>
        project.images
          ? project.images
              .filter(image =>
                image.tags && image.tags.some(tag => tag.name === selectedTag.name)
              )
              .map(image => ({
                id: project.id, // добавляем id проекта для корректной ссылки
                projectTitle: project.title,
                projectCategory: project.category.name,
                image: image.image,
                link: project.link, // добавляем link проекта для корректной ссылки
              }))
          : []
      )
    : [];

  return (
    <div className="project">
      <div className="project-header">
        <h1>
          Proiectele noastre<p>Acasă / Proiect</p>
        </h1>
      </div>
      <div className="project-categories">
        <ul>
          {imageTags.map((tag, index) => (
            <ol
              key={index}
              className={`p-ctg ${selectedTag === tag ? 'takenCategory' : 'notakenCategory'}`}
              onClick={() => handleTagSelection(tag)}
            >
              {tag.name}
            </ol>
          ))}
        </ul>
      </div>
      <div className="our-projects">
        {selectedTag && filteredImages.length > 0 ? (
          filteredImages.map((item, ind) => (
            <ProjectCard key={ind} project={item} />
          ))
        ) : !selectedTag ? (
          projects.map((project, ind) => (
            <ProjectCard
              key={ind}
              project={{
                id: project.id, // добавляем id проекта для корректной ссылки
                projectTitle: project.title,
                projectCategory: project.category.name,
                image: project.main_image,
                link: project.link, // добавляем link проекта для корректной ссылки
              }}
            />
          ))
        ) : (
          <p>Lucrăm la acest spațiu</p>
        )}
      </div>
    </div>
  );
}
