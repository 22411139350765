import React, { useEffect, useState } from "react";
import "./serviceSingle.css";
import ReactPlayer from "react-player/youtube";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import CountUp from "react-countup";

export function ServiceSingle() {
  const { slug } = useParams();
  const [serviceDetail, setServiceDetail] = useState(null);
  const [statistics, setStatistics] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`/api/check-page-status/service-single/`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== 'online') {
          navigate('/error');
        } else {
          fetch(`/api/service-single/${slug}/`)
            .then((response) => response.json())
            .then((data) => setServiceDetail(data))
            .catch((error) => console.error("Ошибка при загрузке данных услуги:", error));

          // Fetching statistics
          fetch("/api/statistics/")
            .then((response) => response.json())
            .then((data) => {
              if (data) {
                setStatistics(data);
              } else {
                setStatistics(null);
              }
            })
            .catch((error) => console.error("Error fetching statistics:", error));
        }
      })
      .catch((error) => console.error("Ошибка при проверке статуса страницы:", error));
  }, [navigate, slug]);

  if (!serviceDetail) {
    return <div>Загрузка...</div>;
  }

  // Функция для обработки элементов списка
  const renderListItems = (items) => {
    if (Array.isArray(items)) {
      return items.map((item, index) => {
        const [mainItem, ...subItems] = item.split(':');

        return (
          <li key={index}>
            <strong>{mainItem.trim()}:</strong>
            {subItems.length > 0 && (
              <ul style={{ marginTop: '10px', marginLeft: '20px', listStyleType: 'disc' }}>
                {subItems.join(':').split('.').map((subItem, subIndex) => (
                  subItem.trim() && <li key={subIndex}>{subItem.trim()}</li>
                ))}
              </ul>
            )}
          </li>
        );
      });
    } else {
      console.error('Expected an array but got:', items);
      return null;
    }
  };

  return (
    <div className="serviceSingle">
      <div className="serviceS-header" style={{ backgroundImage: `url(${serviceDetail.service_image})` }}>
        <h1>
          {serviceDetail.title}<p>Home / Detalii serviciu</p>
        </h1>
      </div>
      <div className="serviceS-info">
        <div className="ss-info-detail">
          <h1>{serviceDetail.title}</h1>
          <p dangerouslySetInnerHTML={{ __html: serviceDetail.description }}></p>
        </div>
        <div className="ss-info-brands">
          <ul>
            {serviceDetail.partners && serviceDetail.partners.map((partner, index) => (
              <li key={index}>
                <img src={partner.logo} alt={partner.name} />
              </li>
            ))}
          </ul>
        </div>
        {serviceDetail.video_url && (
          <div className="ss-info-video">
            <ReactPlayer
              width="100%"
              height="350px"
              className="react-player"
              controls
              light
              url={serviceDetail.video_url}
            />
          </div>
        )}
        <div className="ss-pointers">
          <div className="ss-pointer one">
            <h1>{serviceDetail.list1_title}</h1>
            <ul>
              {renderListItems(serviceDetail.list1_items)}
            </ul>
          </div>
          <div className="ss-pointer two">
            <h1>{serviceDetail.list2_title}</h1>
            <ul>
              {renderListItems(serviceDetail.list2_items)}
            </ul>
          </div>
        </div>
        <div className="go-portfolio">
          <div className="gp-img">
            <img src={serviceDetail.service_image} alt="service" />
          </div>
          <div className="gp-text">
            <h1>{serviceDetail.title}</h1>
            <p>{serviceDetail.interesting_fact}</p>
            <Link to={`/projects`}><button>Our Portfolio <BsArrowRight style={{ marginLeft: "2%", color: "#CDA274" }} /></button></Link>
          </div>
        </div>
      </div>

      {statistics && statistics.status === 'online' && (
        <div className="experience">
          <div className="h-years">
            <CountUp className="h-year num" duration={4} end={statistics.years_of_experience} />
            <p>Years Of Experience</p>
          </div>
          <div className="h-s-project">
            <CountUp duration={4} className="h-sp num" end={statistics.success_projects} />
            <p>Success Project</p>
          </div>
          <div className="h-a-project">
            <CountUp duration={4} className="h-ap num" end={statistics.active_projects} />
            <p>Active Project</p>
          </div>
          <div className="h-customers">
            <CountUp duration={4} className="h-cust num" end={statistics.happy_customers} />
            <p>Happy Customers</p>
          </div>
        </div>
      )}
    </div>
  );
}
