import "./blogDetails.css";
import {
  FaInstagram,
  FaFacebookF,
  FaTelegramPlane,
  FaWhatsapp,
} from "react-icons/fa";
import { BsArrowRight, BsSearch } from "react-icons/bs";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export function BlogDetails() {
  const { slug } = useParams();  // Захватываем slug из URL
  const navigate = useNavigate();  // Используем для перенаправления
  const [post, setPost] = useState(null);
  const [tags, setTags] = useState([]);
  const [contactInfo, setContactInfo] = useState({});

  useEffect(() => {
    // Проверка статуса страницы "Blog"
    fetch(`/api/check-page-status/blog/${slug}/`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== 'online') {
          navigate('/error');  // Редирект на страницу 404, если страница не в статусе "онлайн"
        }
      })
      .catch((error) => console.error('Ошибка при проверке статуса страницы:', error));

    // Запрашиваем новость, используя slug
    fetch(`/api/news/${slug}/`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => setPost(data))
      .catch(error => console.error('Ошибка при загрузке статьи:', error));

    // Запрашиваем все теги
    fetch('/api/all-tags/')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => setTags(data))
      .catch(error => console.error('Ошибка при загрузке тегов:', error));

    // Запрашиваем контактную информацию
    fetch('/api/contact-info/')
      .then(response => response.json())
      .then(data => setContactInfo(data))
      .catch(error => console.error('Ошибка при загрузке контактной информации:', error));
  }, [slug, navigate]);

  if (!post) {
    return <div>Loading...</div>;
  }

  const handleChange = (e) => {
    const pClassList = e.target.parentElement;
    if (e.target.classList.contains("nonselected")) {
      for (let i = 0; i < pClassList.childNodes.length; i++) {
        if (pClassList.childNodes[i].className === ("blogD-tag selected")) {
          pClassList.childNodes[i].className = "blogD-tag nonselected";
        }
      }
      e.target.classList.remove("nonselected");
      e.target.classList.add("selected");
    } else if (e.target.classList.contains("selected")) {
      for (let i = 0; i < pClassList.childNodes.length; i++) {
        if (pClassList.childNodes[i].className === ("blogD-tag selected")) {
          pClassList.childNodes[i].className = "blogD-tag nonselected";
        }
      }
    }
  };

  return (
    <div className="blogDetails">
      <div className="blogD-header"></div>
      <div className="blogDetailsAll">
        <div className="blog-content">
          <h1>{post.title}</h1>
          <div className="bc-photo">
            <img src={post.main_image} alt="blog" />
          </div>
          <div className="datePath">
            <p className="date">{new Date(post.published_date).toLocaleDateString()}</p>
            <p className="path">{post.categories.map(category => category.name).join(' / ')}</p>
          </div>
          <div className="bc-text t1">
            <p>{post.interesting_story}</p>
          </div>
          <div className="blogD-quotes">
            <div className="blogD-q-content">
              <h1>“</h1>
              <p className="blogD-q-comment">
                The details are not the details. They make the design.
              </p>
            </div>
          </div>
          <div className="bc-text t2">
            <h1>Design sprints are great</h1>
            <p dangerouslySetInnerHTML={{ __html: post.content }} />
          </div>
          {post.images.length > 0 && (
            <div className="bc-photo">
              <img src={post.images[0].image} alt="blog" />
            </div>
          )}
          <div className="bc-text t3">
            {post.experience_title && <h1>{post.experience_title}</h1>}
            {post.experience_content && <p>{post.experience_content}</p>}
          </div>
          <div className="blogD-share">
            <div className="bd-tags">
              <div className="bd-main-tag">
                <p className="tag">Tags</p>
              </div>
              <div className="bd-other-tags">
                {post.tags.map(tag => (
                  <p key={tag.name} className="one">{tag.name}</p>
                ))}
              </div>
            </div>
            <div className="bd-smedias">
              <ul>
                {contactInfo.facebook_url && (
                  <li><a href={contactInfo.facebook_url} target="_blank" rel="noopener noreferrer"><FaFacebookF /></a></li>
                )}
                {contactInfo.instagram_url && (
                  <li><a href={contactInfo.instagram_url} target="_blank" rel="noopener noreferrer"><FaInstagram /></a></li>
                )}
                {contactInfo.telegram_url && (
                  <li><a href={contactInfo.telegram_url} target="_blank" rel="noopener noreferrer"><FaTelegramPlane /></a></li>
                )}
                {contactInfo.whatsapp_url && (
                  <li><a href={contactInfo.whatsapp_url} target="_blank" rel="noopener noreferrer"><FaWhatsapp /></a></li>
                )}
              </ul>
            </div>
          </div>
          <div className="blog-leave-reply">
            <p>Leave a Reply</p>
            <div className="nameEmail">
              <input name="fullname" placeholder="Name" />
              <input name="mail" placeholder="Email" />
            </div>
            <div className="sitePhone">
              <input name="site" placeholder="Subject" />
              <input name="phone" placeholder="Phone" />
            </div>
            <div className="interested">
              <textarea
                name="interested"
                placeholder="Hello, I am interested in.."
              />
            </div>
            <div className="blogD-saving-send">
              <div className="blogD-saving">
                <input type="checkbox" name="remember-mail" />
                <label>
                  Save my name, email, and website in this browser for the next
                  time I comment.
                </label>
              </div>
              <div className="send">
                <button>
                  Send Now
                  <BsArrowRight style={{ marginLeft: "5px" }} color="#CDA274" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="blogD-sidebar">
          <div className="search-box">
            <input type="search" name="search" placeholder="Search" />
            <button>
              <BsSearch />
            </button>
          </div>
          <div className="latest-news">
            <h2>Latest News</h2>
            {/* Последние новости, которые можно также получить с сервера */}
          </div>
          <div className="blogD-categories">
            <h2>Categories</h2>
            <ul>
              {post.categories.map(category => (
                <ol key={category.name}>{category.name}</ol>
              ))}
            </ul>
          </div>
          <div className="blogD-alltags">
            <h2>Tags</h2>
            <div className="blogD-tags">
              {tags.map(tag => (
                <p key={tag.name}
                  className="blogD-tag nonselected"
                  onClick={handleChange}
                >
                  {tag.name}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
