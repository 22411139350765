import {
  FaInstagram,
  FaFacebookF,
  FaTelegramPlane,
  FaWhatsapp,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import "./footer.css";

export function Footer() {
  const [contactInfo, setContactInfo] = useState(null);
  const [logo, setLogo] = useState(null);
  const [pageStatus, setPageStatus] = useState({
    aboutUs: false,
    projects: false,
    team: false,
    contact: false,
    services: false,
    terms: false,
    privacyPolicy: false,
    cookiesPolicy: false,
    faq: false,
  });
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [contactInfoRes, logoRes, servicesRes, aboutUsRes, projectsRes, teamRes, contactRes, servicesRes2, termsRes, privacyPolicyRes, cookiesPolicyRes, faqRes] = await Promise.all([
          fetch('/api/contact-info/').then(res => res.json()),
          fetch('/api/site-logo/').then(res => res.json()),
          fetch('/api/services/').then(res => res.json()),
          fetch('/api/check-page-status/aboutus/').then(res => res.json()),
          fetch('/api/check-page-status/projects/').then(res => res.json()),
          fetch('/api/check-page-status/team/').then(res => res.json()),
          fetch('/api/check-page-status/contact/').then(res => res.json()),
          fetch('/api/check-page-status/services/').then(res => res.json()),
          fetch('/api/check-page-status/terms/').then(res => res.json()),
          fetch('/api/check-page-status/privacy-policy/').then(res => res.json()),
          fetch('/api/check-page-status/cookies-policy/').then(res => res.json()),
          fetch('/api/check-page-status/faq/').then(res => res.json()),
        ]);

        setContactInfo(contactInfoRes);
        setLogo(logoRes.logo);
        setServices(servicesRes.slice(0, 5));
        setPageStatus({
          aboutUs: aboutUsRes.status === 'online',
          projects: projectsRes.status === 'online',
          team: teamRes.status === 'online',
          contact: contactRes.status === 'online',
          services: servicesRes2.status === 'online',
          terms: termsRes.status === 'online',
          privacyPolicy: privacyPolicyRes.status === 'online',
          cookiesPolicy: cookiesPolicyRes.status === 'online',
          faq: faqRes.status === 'online',
        });
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      }
    };

    fetchData();
  }, []);

  if (!contactInfo || !logo) {
    return <div>Loading...</div>; // Загрузка данных
  }

  return (
    <div className="footer">
      <div className="f-info">
        <section className="f-social-medias">
          <div className="f-logo-text">
            <Link style={{ display: "flex" }} to="/">
              <div className="f-logo">
                <img src={logo} alt="logo"></img> {/* Используем динамический логотип */}
              </div>
            </Link>
          </div>
          <div className="f-about-text">
            <p>Interior care vorbește despre tine fără cuvinte</p>
          </div>
          <div className="f-smedia">
            <ul>
              {contactInfo.facebook_url && (
                <li>
                  <a href={contactInfo.facebook_url} target="_blank" rel="noopener noreferrer">
                    <FaFacebookF />
                  </a>
                </li>
              )}
              {contactInfo.instagram_url && (
                <li>
                  <a href={contactInfo.instagram_url} target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                  </a>
                </li>
              )}
              {contactInfo.telegram_url && (
                <li>
                  <a href={contactInfo.telegram_url} target="_blank" rel="noopener noreferrer">
                    <FaTelegramPlane />
                  </a>
                </li>
              )}
              {contactInfo.whatsapp_url && (
                <li>
                  <a href={contactInfo.whatsapp_url} target="_blank" rel="noopener noreferrer">
                    <FaWhatsapp />
                  </a>
                </li>
              )}
            </ul>
          </div>
        </section>
        <section className="f-pages">
          <p>Pagini</p>
          <ul>
            {pageStatus.aboutUs && (
              <li>
                <Link to={`/aboutus`}>Despre Noi</Link>
              </li>
            )}
            {pageStatus.projects && (
              <li>
                <Link to={`/projects`}>Proiectele Noastre</Link>
              </li>
            )}
            {pageStatus.team && (
              <li>
                <Link to={`/team`}>Echipa Noastră</Link>
              </li>
            )}
            {pageStatus.contact && (
              <li>
                <Link to={`/services`}>Servicii</Link>
              </li>
            )}
            {pageStatus.services && (
              <li>
                <Link to={`/contact`}>Contactează-ne</Link>
              </li>
            )}
          </ul>
        </section>
        <section className="f-services">
          <p>Servicii</p>
          <ul>
            {services.map((service, index) => (
              <li key={index}>
                <Link to={`/service-single/${service.slug}`}>{service.title}</Link>
              </li>
            ))}
          </ul>
        </section>
        <section className="f-contact">
          <p>Contact</p>
          <p>{contactInfo.address}</p>
          {contactInfo.contact_email && (
            <p>
              <a href={`mailto:${contactInfo.contact_email}`}>
                {contactInfo.contact_email}
              </a>
            </p>
          )}
          {contactInfo.contact_phone && (
            <p>
              <a href={`tel:${contactInfo.contact_phone}`}>
                {contactInfo.contact_phone}
              </a>
            </p>
          )}
        </section>
      </div>
      <div className="terms">
        <ul>
          {pageStatus.terms && (
            <ol>
              <Link to={`/terms`}>&bull; Termeni și Condiții</Link>
            </ol>
          )}
          {pageStatus.privacyPolicy && (
            <ol>
              <Link to={`/privacy-policy`}>&bull; Politica de Confidențialitate</Link>
            </ol>
          )}
          {pageStatus.cookiesPolicy && (
            <ol>
              <Link to={`/cookies-policy`}>&bull; Politica Cookies</Link>
            </ol>
          )}
          {pageStatus.faq && (
            <ol>
              <Link to={`/faq`}>&bull; Întrebări Frecvente</Link>
            </ol>
          )}
        </ul>
      </div>
      <div className="f-copyright">
        <p>Copyright © Aneta | Aneta Design Interior</p>
      </div>
    </div>
  );
}
