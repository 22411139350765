import "./projectCard.css";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

export function ProjectCard({ project }) {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/project-details/${project.link}`);
  };

  return (
    <div className="op-project" onClick={handleCardClick}>
      <div className="op-pro-img">
        <img src={project.image} alt={project.projectTitle} />
      </div>
      <div className="op-pro-detail">
        <div className="op-pro-info">
          <p className="op-prj-title">{project.projectTitle}</p>
          <p className="op-prj-path">{project.projectCategory}</p>
        </div>
        <div className="op-pro-btn">
          <Link to={`/project-details/${project.link}`} onClick={(e) => e.stopPropagation()}>
            <button>
              <IoIosArrowForward />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
