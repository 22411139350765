import React, { useEffect, useState } from "react";
import "./services.css";
import { BsArrowRight } from "react-icons/bs";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import icon1 from "../images/Icon1.svg";
import icon2 from "../images/Icon2.svg";
import icon3 from "../images/Icon3.svg";
import icon4 from "../images/Icon4.svg";

export function Services() {
  const [services, setServices] = useState([]);
  const [workProcess, setWorkProcess] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Используем Promise.all для объединения запросов
    fetch("/api/check-page-status/services/")
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== "online") {
          navigate("/error"); // Перенаправление на страницу ошибки, если страница не онлайн
        } else {
          // Если страница онлайн, выполняем все запросы одновременно
          Promise.all([
            fetch("/api/services/").then((res) => res.json()),
            fetch("/api/work-processes/").then((res) => res.json())
          ])
            .then(([servicesData, workProcessData]) => {
              setServices(servicesData); // Устанавливаем данные услуг
              if (Array.isArray(workProcessData) && workProcessData.length > 0) {
                setWorkProcess(workProcessData[0]); // Устанавливаем первый рабочий процесс
              } else {
                console.error("No work process data found.");
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
      })
      .catch((error) => console.error("Error checking page status:", error));
  }, [navigate]);

  const handleChange = (e) => {
    const pClass = e.target.parentElement;
    if (e.target.className === "service-type") {
      for (let i = 0; i < pClass.childNodes.length; i++) {
        pClass.childNodes[i].className = "service-type";
      }
      e.target.classList.add("selected-type");
    } else if (e.target.className === "service-type selected-type") {
      for (let i = 0; i < pClass.childNodes.length; i++) {
        pClass.childNodes[i].className = "service-type";
      }
    }
  };

  const handleChangeTwo = (e) => {
    const pClass = e.target.parentElement.parentElement;
    if (e.target.parentElement.className === "service-type") {
      for (let i = 0; i < pClass.childNodes.length; i++) {
        pClass.childNodes[i].className = "service-type";
      }
      e.target.parentElement.classList.add("selected-type");
    } else if (e.target.parentElement.className === "service-type selected-type") {
      for (let i = 0; i < pClass.childNodes.length; i++) {
        pClass.childNodes[i].className = "service-type";
      }
    }
  };

  const icons = [icon1, icon2, icon3, icon4];

  return (
    <div className="services">
      <div className="services-header">
        <h1>
          Servicii<p>Acasă / Servicii</p>
        </h1>
      </div>
      <div className="service-types">
        {services.map((type, index) => (
          <div className="service-type" onClick={handleChange} key={index}>
            <h2 onClick={handleChangeTwo}>{type.title}</h2>
            <p
              onClick={handleChangeTwo}
              dangerouslySetInnerHTML={{ __html: type.description }}
            />
            <Link to={`/service-single/${type.slug}`}>
              <button>
                Află mai multe{" "}
                <BsArrowRight style={{ marginLeft: "5px" }} color="#CDA274" />
              </button>
            </Link>
          </div>
        ))}
      </div>

      {workProcess && workProcess.steps && Array.isArray(workProcess.steps) && (
        <div className="howWeWork">
          <div className="how-title">
            <h1>{workProcess.title}</h1>
            <p dangerouslySetInnerHTML={{ __html: workProcess.description }} />
          </div>
          {workProcess.steps.map((step, index) => (
            <div className="how-content" key={step.id}>
              {index % 2 === 0 ? (
                <>
                  <div className="hc-img">
                    <img src={step.image} alt="concept"></img>
                  </div>
                  <div className="hc-text">
                    <div className="hc-text-anima">
                      <img src={icons[index % icons.length]} alt="icon"></img>
                      <p>{String(index + 1).padStart(2, "0")}</p>
                    </div>
                    <div className="hc-subtext">
                      <h2>{step.title}</h2>
                      <p dangerouslySetInnerHTML={{ __html: step.description }} />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="hc-text">
                    <div className="hc-text-anima">
                      <img src={icons[index % icons.length]} alt="icon"></img>
                      <p>{String(index + 1).padStart(2, "0")}</p>
                    </div>
                    <div className="hc-subtext">
                      <h2>{step.title}</h2>
                      <p dangerouslySetInnerHTML={{ __html: step.description }} />
                    </div>
                  </div>
                  <div className="hc-img">
                    <img src={step.image} alt="concept"></img>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      )}

      <div className="interno">
        <h1>Gata pentru o schimbare?</h1>
        <p>Contactați-ne pentru a discuta cum vă putem ajuta să creați spațiul perfect care să se potrivească stilului dumneavoastră</p>
        <Link to="/contact">
          <button>
            Contactați cu noi
            <AiOutlineArrowRight
              style={{ marginLeft: "5px" }}
              color="#292F36"
            />
          </button>
        </Link>
      </div>
    </div>
  );
}
