import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import "./header.css";

export function Header() {
  const [logo, setLogo] = useState(null);
  const [pageStatus, setPageStatus] = useState({
    home: false,
    aboutUs: false,
    projects: false,
    team: false,
    contact: false,
    services: false,
    blog: false,
  });

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const translations = {
    home: "Acasă",
    aboutUs: "Despre Noi",
    projects: "Proiecte",
    team: "Echipă",
    contact: "Contact",
    services: "Servicii",
    blog: "Blog"
  };

  useEffect(() => {
    fetch('/api/site-logo/')
      .then((response) => response.json())
      .then((data) => {
        setLogo(data.logo);
      })
      .catch((error) => console.error('Ошибка при загрузке логотипа:', error));

    const checkPageStatus = async () => {
      const statuses = await Promise.all([
        fetch('/api/check-page-status/home/').then(res => res.json()),
        fetch('/api/check-page-status/aboutus/').then(res => res.json()),
        fetch('/api/check-page-status/projects/').then(res => res.json()),
        fetch('/api/check-page-status/team/').then(res => res.json()),
        fetch('/api/check-page-status/contact/').then(res => res.json()),
        fetch('/api/check-page-status/services/').then(res => res.json()),
        fetch('/api/check-page-status/blog/').then(res => res.json()),
      ]);

      setPageStatus({
        home: statuses[0].status === 'online',
        aboutUs: statuses[1].status === 'online',
        projects: statuses[2].status === 'online',
        team: statuses[3].status === 'online',
        contact: statuses[4].status === 'online',
        services: statuses[5].status === 'online',
        blog: statuses[6].status === 'online',
      });
    };

    checkPageStatus();

    const handleScroll = () => {
      const header = document.querySelector('.header');
      if (window.scrollY > 0) {
        header.classList.add('header-scrolled');
      } else {
        header.classList.remove('header-scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Удаляем обработчик при размонтировании компонента
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="header">
      <div className="header-logo-text">
        <Link to="/" className="logo-link">
          <div className="header-logo">
            <img src={logo} alt="logo"></img>
          </div>
        </Link>
        <button className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          ☰
        </button>
      </div>
      <div className={`header-pages ${isMenuOpen ? 'open' : ''}`}>
        <ul>
          {pageStatus.home && (
            <li><Link to={`/`} onClick={handleLinkClick}>{translations.home}</Link></li>
          )}
          {pageStatus.aboutUs && (
            <li><Link to={`/aboutus`} onClick={handleLinkClick}>{translations.aboutUs}</Link></li>
          )}
          {pageStatus.projects && (
            <li><Link to={`/projects`} onClick={handleLinkClick}>{translations.projects}</Link></li>
          )}
          {pageStatus.team && (
            <li><Link to={`/team`} onClick={handleLinkClick}>{translations.team}</Link></li>
          )}
          {pageStatus.services && (
            <li><Link to={`/services`} onClick={handleLinkClick}>{translations.services}</Link></li>
          )}
          {pageStatus.blog && (
            <li><Link to={`/blog`} onClick={handleLinkClick}>{translations.blog}</Link></li>
          )}
          {pageStatus.contact && (
            <li><Link to={`/contact`} onClick={handleLinkClick}>{translations.contact}</Link></li>
          )}
        </ul>
      </div>
    </div>
  );
}
