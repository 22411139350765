import "./contact.css";
import { useEffect, useState } from "react";
import { GiWorld } from "react-icons/gi";
import { HiOutlineMail } from "react-icons/hi";
import { BsTelephone, BsArrowRight } from "react-icons/bs";
import { FaInstagram, FaFacebookF, FaTelegramPlane, FaWhatsapp, FaMapMarkerAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import Cookies from "js-cookie";

export function Contact() {
  const [contactInfo, setContactInfo] = useState(null);
  const [formData, setFormData] = useState({
    fullname: "",
    mail: "",
    subject: "",
    phone: "",
    interested: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch contact information
    fetch('/api/contact-info/')
      .then((response) => response.json())
      .then((data) => setContactInfo(data))
      .catch((error) => console.error('Ошибка при загрузке контактной информации:', error));

    // Check if the page is online
    fetch('/api/check-page-status/contact/')
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== 'online') {
          navigate('/error');
        }
      })
      .catch((error) => console.error('Ошибка при проверке статуса страницы:', error));
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors({});  // Сброс ошибок перед отправкой

    try {
      const csrfToken = Cookies.get('csrftoken');
      const response = await axios.post("/api/send-contact-form/", {
        ...formData,
        page_url: window.location.href,  // Добавление текущего URL
        page_name: "Contact Page",  // Название страницы, может быть динамическим
      }, {
        headers: {
          'X-CSRFToken': csrfToken
        }
      });
      if (response.status === 200) {
        alert("Your message has been sent successfully!");
        setFormData({
          fullname: "",
          mail: "",
          subject: "",
          phone: "",
          interested: "",
        });
      } else if (response.status === 400) {
        setErrors(response.data.errors);
      } else {
        alert("An error occurred while sending your message");
      }
    } catch (error) {
      console.error("Error sending message:", error.response ? error.response.data : error.message);
      alert("An error occurred while sending your message");
    }

    setIsSubmitting(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: value });
  };

  if (!contactInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div className="contact">
      <div className="contact-header">
        <h1>
          Contactaţi-ne<p>Acasă / Contact</p>
        </h1>
      </div>
      <div className="contact-content">
        <h2>Ne place să întâlnim oameni noi și să le facem spațiul special</h2>
        <div className="contact-form">
          <div className="contact-form-info">
            <div className="icons">
              {contactInfo.address && (
                <p>
                  <span className="icon">
                    <FaMapMarkerAlt />
                  </span>
                  {contactInfo.address}
                </p>
              )}
              {contactInfo.contact_email && (
                <p>
                  <span className="icon">
                    <HiOutlineMail />
                  </span>
                  <a href={`mailto:${contactInfo.contact_email}`}>{contactInfo.contact_email}</a>
                </p>
              )}
              {contactInfo.contact_phone && (
                <p>
                  <span className="icon">
                    <BsTelephone />
                  </span>
                  <a href={`tel:${contactInfo.contact_phone}`}>{contactInfo.contact_phone}</a>
                </p>
              )}
              <p>
                <span className="icon">
                  <GiWorld />
                </span>
                <a href={window.location.origin} target="_blank" rel="noopener noreferrer">
                  {window.location.origin}
                </a>
              </p>
            </div>
            <div className="contact-smedias">
              <ul>
                {contactInfo.facebook_url && (
                  <li>
                    <a href={contactInfo.facebook_url} target="_blank" rel="noopener noreferrer">
                      <FaFacebookF />
                    </a>
                  </li>
                )}
                {contactInfo.instagram_url && (
                  <li>
                    <a href={contactInfo.instagram_url} target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </li>
                )}
                {contactInfo.telegram_url && (
                  <li>
                    <a href={contactInfo.telegram_url} target="_blank" rel="noopener noreferrer">
                      <FaTelegramPlane />
                    </a>
                  </li>
                )}
                {contactInfo.whatsapp_url && (
                  <li>
                    <a href={contactInfo.whatsapp_url} target="_blank" rel="noopener noreferrer">
                      <FaWhatsapp />
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <form onSubmit={handleSubmit} className="contact-form-fill">
            <div className="nameEmail">
              <input
                name="fullname"
                placeholder="Nume"
                value={formData.fullname}
                onChange={handleChange}
              />
              {errors.fullname && <p className="error">{errors.fullname}</p>}
              <input
                name="mail"
                placeholder="Email"
                value={formData.mail}
                onChange={handleChange}
              />
              {errors.mail && <p className="error">{errors.mail}</p>}
            </div>
            <div className="subjectPhone">
              <input
                name="subject"
                placeholder="Subiect"
                value={formData.subject}
                onChange={handleChange}
              />
              <PhoneInput
                country={'ro'}
                value={formData.phone}
                onChange={handlePhoneChange}
                containerClass="phone-input"
                inputClass="phone-input"
              />
              {errors.phone && <p className="error">{errors.phone}</p>}
            </div>
            <div className="interested">
              <textarea
                name="interested"
                placeholder="Salut, sunt interesat de..."
                value={formData.interested}
                onChange={handleChange}
              />
              {errors.interested && <p className="error">{errors.interested}</p>}
            </div>
            <div className="send">
              <button type="submit" disabled={isSubmitting}>
                Trimite acum
                <BsArrowRight style={{ marginLeft: "5px" }} color="#CDA274" />
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="map" dangerouslySetInnerHTML={{ __html: contactInfo.map_url }} />
    </div>
  );
}
