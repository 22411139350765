import "./about.css";
import { BsArrowRight } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

export function About() {
  const [quote, setQuote] = useState(null);
  const [sections, setSections] = useState([]);
  const [formData, setFormData] = useState({
    fullname: "",
    mail: "",
    interested: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Проверка статуса страницы "About"
    fetch(`/api/check-page-status/aboutus/`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== "online") {
          navigate("/error"); // Редирект на страницу 404, если страница не в статусе "онлайн"
        }
      })
      .catch((error) =>
        console.error("Ошибка при проверке статуса страницы:", error)
      );

    // Загрузка цитаты и секций
    fetch(`/api/about-page-content/`)
      .then((response) => response.json())
      .then((data) => {
        setQuote(data.quote);
        setSections(data.sections);
      })
      .catch((error) => console.error("Ошибка при загрузке контента:", error));
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors({});

    try {
      const csrfToken = Cookies.get("csrftoken");
      const response = await axios.post(
        "/api/send-contact-form/",
        {
          ...formData,
          page_url: window.location.href, // Добавление текущего URL
          page_name: "About Us Page", // Название страницы
        },
        {
          headers: {
            "X-CSRFToken": csrfToken,
          },
        }
      );
      if (response.status === 200) {
        alert("Your message has been sent successfully!");
        setFormData({
          fullname: "",
          mail: "",
          interested: "",
        });
      } else if (response.status === 400) {
        setErrors(response.data.errors);
      } else {
        alert("An error occurred while sending your message");
      }
    } catch (error) {
      console.error(
        "Error sending message:",
        error.response ? error.response.data : error.message
      );
      alert("An error occurred while sending your message");
    }

    setIsSubmitting(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNavigateToProjects = () => {
    navigate('/projects'); // Редирект на страницу "Projects"
  };

  return (
    <div className="about">
      <div className="about-header">
        <h1>
          Despre Noi<p>Acasă / Despre Noi</p>
        </h1>
      </div>

      {quote && (
        <div className="quotes">
          <div className="pattern first">
            <p></p>
          </div>
          <div className="content">
            <h1>“</h1>
            <p className="comment">{quote.text}</p>
            <p className="author">- {quote.author}</p>
          </div>
          <div className="pattern second"></div>
        </div>
      )}

      <div className="about-concept">
        {sections.map((section, index) => (
          <div
            key={section.id}
            className={`con ${index % 2 === 0 ? "firstPart" : "secondPart"}`}
          >
            <div className="c-text">
              <h1>{section.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: section.content }} />
              {index < 2 && ( // Оставляем кнопки только для первых двух блоков
                <button onClick={handleNavigateToProjects}>
                  Conceptul nostru
                  <BsArrowRight
                    style={{ color: "#CDA274", marginLeft: "3%" }}
                  />
                </button>
              )}
            </div>
            <div className="concept-img">
              {section.image && (
                <img src={section.image} alt={section.title}></img>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="creative-pro">
        <h1>Proiect creativ? Să avem o discuție productivă.</h1>
        <div className="creMailBox">
          <form onSubmit={handleSubmit}>
            <div className="creNameEmail">
              <input
                name="fullname"
                placeholder="Nume"
                value={formData.fullname}
                onChange={handleChange}
              />
              {errors.fullname && <p className="error">{errors.fullname}</p>}
              <input
                name="mail"
                placeholder="Email"
                value={formData.mail}
                onChange={handleChange}
              />
              {errors.mail && <p className="error">{errors.mail}</p>}
            </div>
            <div className="cre-text">
              <textarea
                name="interested"
                placeholder="Salut, sunt interesat de..."
                value={formData.interested}
                onChange={handleChange}
              />
              {errors.interested && (
                <p className="error">{errors.interested}</p>
              )}
            </div>
            <div className="cre-send">
              <button type="submit" disabled={isSubmitting}>
                Trimite acum
                <BsArrowRight style={{ marginLeft: "5px" }} color="#CDA274" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
