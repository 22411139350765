import './App.css';
import { useEffect } from 'react';
import { Contact } from './contact/contact';
import { Header } from './headerFooter/header';
import { Footer } from './headerFooter/footer';
import { RestrictedPage } from './restrictedPage/restricted';
import { PricingPlan } from './pricingPlan/pricingPlan';
import { Faq } from './faq/faq';
import { ProjectDetails } from './projectDetails/projectDetails';
import { Team } from './team/team';
import { TeamSingle } from './teamSingle/teamSingle';
import { About } from './about/about';
import { NotFound } from './404/404';
import { BlogDetails } from './blogDetails/blogDetails';
import { Services } from './servicesPage/services';
import { ServiceSingle } from './serviceSingle/serviceSingle';
import { Home } from './home/home';
import { Blog } from './blog/blog';
import { Project } from './project/project';
import { Terms } from './Allterms/terms';
import { Cookies } from './Allterms/cookies';
import { Privacy } from './Allterms/privacy';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { SmoothScroll } from './smooth';

function App() {

useEffect(() => {
    fetch('/api/scripts/')
      .then(response => response.json())
      .then(data => {
        data.forEach(setting => {
          if (setting.script) {
            // Создаем временный элемент div для парсинга содержимого скрипта
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = setting.script.trim();

            // Добавляем все скрипты из tempDiv в head
            const scripts = tempDiv.getElementsByTagName('script');
            for (let script of scripts) {
              const newScript = document.createElement('script');
              if (script.src) {
                // Если скрипт имеет src, копируем его
                newScript.src = script.src;
                newScript.async = script.async || true;
              } else {
                // Вставляем текст скрипта, если он inline
                newScript.textContent = script.innerHTML;
              }
              document.head.appendChild(newScript);
              console.log(`Script embedded: ${newScript.src || "inline script"}`);
            }
          }
        });
      })
      .catch(error => {
        console.error('Error loading scripts:', error);
      });
  }, []);


  return (
    <BrowserRouter>
      <SmoothScroll />
      <Header />
      <Routes>
        <Route path='/contact' element={<Contact/>}></Route>
        <Route path='/restricted-page' element={<RestrictedPage/>}></Route>
        <Route path='/pricing' element={<PricingPlan/>}></Route>
        <Route path='/faq' element={<Faq/>}></Route>
        <Route path='/project-details/:link' element={<ProjectDetails/>}></Route>
        <Route path='/team' element={<Team/>}></Route>
        <Route path='/team-single' element={<TeamSingle/>}></Route>
        <Route path='/aboutus' element={<About/>}></Route>
        <Route path='/error' element={<NotFound/>}></Route>
        <Route path='/blog-details/:slug' element={<BlogDetails />} />
        <Route path='/services' element={<Services/>}></Route>
        <Route path='/service-single/:slug' element={<ServiceSingle />} />
        <Route path='/' element={<Home/>}></Route>
        <Route path='/blog' element={<Blog/>}></Route>
        <Route path='/projects' element={<Project/>}></Route>
        <Route path='/terms' element={<Terms />}></Route>
        <Route path='/cookies-policy' element={<Cookies />}></Route>
        <Route path='/privacy-policy' element={<Privacy />}></Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
