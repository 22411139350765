import "./faq.css";
import { IoIosArrowForward } from "react-icons/io";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function Faq() {
    const [faqData, setFaqData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Проверка статуса страницы
        fetch('/api/check-page-status/faq/')
            .then((response) => response.json())
            .then((data) => {
                if (data.status !== 'online') {
                    navigate('/error');  // Редирект на страницу 404, если страница не в статусе "онлайн"
                }
            })
            .catch((error) => console.error('Ошибка при проверке статуса страницы:', error));

        // Получение данных FAQ
        fetch('/api/faq-list/')
            .then((response) => response.json())
            .then((data) => {
                const formattedData = data.map(block => ({
                    ...block,
                    questions: block.questions.map(q => ({ ...q, show: false }))
                }));
                setFaqData(formattedData);
            })
            .catch((error) => console.error('Ошибка при загрузке данных FAQ:', error));
    }, [navigate]);

    const toggleQuestion = (blockIndex, questionIndex) => {
        const updatedFaqData = faqData.map((block, i) => {
            if (i === blockIndex) {
                const updatedQuestions = block.questions.map((q, j) => {
                    if (j === questionIndex) {
                        return { ...q, show: !q.show };
                    }
                    return q;
                });
                return { ...block, questions: updatedQuestions };
            }
            return block;
        });
        setFaqData(updatedFaqData);
    };

    return (
        <div className="faq">
            <div className="faq-header">
                <h1>
                    Faq's<p>Home / Faq</p>
                </h1>
            </div>
            {faqData.map((block, blockIndex) => (
                <div key={blockIndex}>
                    <h1 className="faq-title">{block.title}</h1>
                    <div className={`faq-block ${blockIndex % 2 === 0 ? 'left' : 'right'}`}>
                        <div className="ep-questions">
                            {block.questions.map((question, questionIndex) => (
                                <div className="ep-q" key={questionIndex}>
                                    <p
                                        className="q-title"
                                        onClick={() => toggleQuestion(blockIndex, questionIndex)}
                                    >
                                        {question.question} <IoIosArrowForward />
                                    </p>
                                    {question.show && (
                                        <p className="q-content">{question.answer}</p>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className="ep-q-img">
                            <img src={block.image} alt={block.title} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
