import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./team.css";
import m0 from "../images/team/member1.png";
import m1 from "../images/team/member2.jpg";
import m2 from "../images/team/member3.png";
import m3 from "../images/team/member4.png";
import m4 from "../images/team/member5.png";
import m5 from "../images/team/member6.png";
import m6 from "../images/team/member7.png";
import m7 from "../images/team/member8.png";
import data from "./team_members.json";
import { FaFacebookF, FaTwitter, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

export function Team() {
  const [isOnline, setIsOnline] = useState(null);  // Хранение статуса страницы
  const navigate = useNavigate();

  // Используем useEffect для проверки статуса страницы при монтировании компонента
  useEffect(() => {
    const checkPageStatus = async () => {
      try {
        const response = await fetch('/api/check-page-status/team/');
        const data = await response.json();

        if (data.status !== 'online') {
          navigate('/error');  // Перенаправление на страницу 404, если статус не 'online'
        } else {
          setIsOnline(true);  // Страница онлайн, отображаем контент
        }
      } catch (error) {
        console.error('Ошибка при проверке статуса страницы:', error);
        navigate('/error');  // В случае ошибки также перенаправляем на страницу 404
      }
    };

    checkPageStatus();
  }, [navigate]);

  if (isOnline === null) {
    return null; // Пока статус не загружен, ничего не рендерим
  }

  return (
    <div className="team">
      <div className="team-header">
        <h1>
          Our Professional<p>Home / Team</p>
        </h1>
      </div>
      <div className="team-members">
        {data.members
          .filter((m, ind) => ind < 8)
          .map((mbr, i) => {
            return (
              <Link to={`/team-single`} key={i}>
                <div className="member">
                  <div className="member-photo">
                    <img src={m5} alt="member"></img>
                  </div>
                  <div className="member-info">
                    <p className="fullname">{mbr.fullname}</p>
                    <p className="m-detail">
                      {mbr.job}, {mbr.country}
                    </p>
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/">
                          <FaFacebookF />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/">
                          <FaLinkedin />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.twitter.com/">
                          <FaTwitter />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Link>
            );
          })}
      </div>
    </div>
  );
}
